<template>
  <v-footer light padless>
    <v-card flat tile class="black--text text-center">
      <v-container fluid :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1161px !important'};`">
        <!-- <v-row>
          <v-col cols="12" md="12">
            <v-row>
              <v-col v-for="(footer, index) in footers" :key="footer + index" cols="12" md="3" class="d-flex flex-column">
                <h4 class="text-justify">
                  {{ footer.title }}
                </h4>
                <p class="text-justify text-pre-line text-left font-medium">
                  {{ footer.content }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" md="3" class="text-left">
                <img :src="logoUrl" alt="Logo" width="60%"/>
                <p class="mt-2">CÔNG TY CỔ PHẦN QUẢN LÝ & PHÁT TRIỂN BĐS CONASI</p>
                <div class="d-flex" style="align-items: flex-start;">
                  <v-icon class="mr-2" size=18>mdi-map-marker</v-icon>
                  <span class="font-medium">58 Nguyễn Đình Chiểu, Phường Đa Kao, Quận 1,Thành phố Hồ Chí Minh, Việt Nam</span>
                </div>
                <div class="d-flex my-2" style="align-items: center;">
                  <v-icon class="mr-2" size=18>mdi-email</v-icon>
                  <span class="font-medium">info@conasi365.vn</span>
                </div>
                <div class="d-flex align-center">
                  <v-icon class="mr-2" size=18>mdi-phone</v-icon>
                  <span class="font-medium">1900 63 68 70</span>
                </div>
                <div class="d-flex align-center mt-3">
                  <a href="http://online.gov.vn/Home/WebDetails/85383"
                     target="_blank"
                     style="text-decoration: none;">
                    <img
                      src="@/assets/logoCCDV.png"
                      alt="Bộ Công Thương"
                      width="160px"
                    />
                  </a>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-left mt-4">
                <span class="my-2 font-large font-weight-bold text-uppercase">Các sản phẩm</span>
                <div class="d-flex my-2">
                  <div style="width: 30%; height: 2px; background-color: orange;"></div>
                  <div style="width: 70%; height: 2px; background-color: rgb(204, 204, 204);"></div>
                </div>
                <div class="font-medium">
                  <p>Voucher khách sạn</p>
                  <p>Voucher tour du lịch (coming soon)</p>
                  <p>Voucher máy bay (coming soon)</p>
                  <p>Chương trình khuyến mãi khác</p>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-left mt-4">
                <span class="my-2 font-large font-weight-bold text-uppercase">Khách sạn / resort</span>
                <div class="d-flex my-2">
                  <div style="width: 30%; height: 2px; background-color: orange;"></div>
                  <div style="width: 70%; height: 2px; background-color: rgb(204, 204, 204);"></div>
                </div>
                <div class="font-medium">
                  <p>L'Alya Ninh Van Bay</p>
                  <p>Ariyana SmartCondotel Nha Trang</p>
                  <p>Căn hộ Dịch vụ Republic Plaza</p>
                </div>
                <span class="mb-2 mt-4 font-large font-weight-bold text-uppercase">Thư viện ảnh</span>
                <div class="d-flex my-2">
                  <div style="width: 30%; height: 2px; background-color: orange;"></div>
                  <div style="width: 70%; height: 2px; background-color: rgb(204, 204, 204);"></div>
                </div>
                <div class="font-medium">
                  <p>Voucher khách sạn</p>
                  <p>Voucher tour du lịch (coming soon)</p>
                  <p>Voucher máy bay (coming soon)</p>
                  <p>Chương trình khuyến mãi khác</p>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-left mt-4">
                <span class="my-2 font-large font-weight-bold text-uppercase">Điều khoản & Quy định</span>
                <div class="d-flex my-2">
                  <div style="width: 30%; height: 2px; background-color: orange;"></div>
                  <div style="width: 70%; height: 2px; background-color: rgb(204, 204, 204);"></div>
                </div>
                <div class="font-medium">
                  <p class="cursor-pointer" @click="onGoTernAndConditions('PrivacyPolicy')">Chính sách bảo mật</p>
                  <p class="cursor-pointer" @click="onGoTernAndConditions('ComplaintsSettlementPolicy')">Chính sách giải quyết khiếu nại</p>
                  <p class="cursor-pointer" @click="onGoTernAndConditions('OperatingRegulations')">Quy chế hoạt động</p>
                  <!-- <p>Chính sách giải quyết tranh chấp</p>
                  <p>Chính sách quyền riêng tư</p> -->
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12">
            <v-row>
              <div class="hr-sect">
                <v-icon>mdi-facebook</v-icon>
                <v-icon class="mx-2">mdi-youtube</v-icon>
                <v-icon>mdi-instagram</v-icon>
              </div>
            </v-row>
            <span class="font-weight-light" style="float: left;">
              <a
                href="http://conasi365.vn/"
                target="_blank"
                style="text-decoration: none;">
                &copy;
                {{ (new Date()).getFullYear() }}
                {{ 'Conasi' }}
              </a>
            </span>
          </v-col>
          <v-col v-if="false" cols="12" md="12">
            <v-row>
              <v-col cols="12" md="3" class="text-left">
                <span class="font-weight-bold font-medium">Số ĐKKD</span>
                <div class="font-medium">
                  <span class="d-block text-uppercase">xxxxxxxxxxxxxxx</span>
                </div>
                <span class="d-block font-weight-bold font-medium">Nơi cấp</span>
                <div class="font-medium">
                  <span>HỒ CHÍ MINH</span>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-left">
                <span class="my-2 font-weight-bold font-medium">Thông tin tài khoản</span>
                <div class="font-medium">
                  <span class="text-uppercase">CÔNG TY CỔ PHẦN QUẢN LÝ VÀ PHÁT TRIỂN BĐS CONASI</span>
                  <span class="d-block text-uppercase">Số tài khoản: xxxxxxxxxxxx</span>
                  <span class="text-uppercase">Chi nhánh: Ngân hàng vietcombank</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
import { mapActions } from 'vuex';
import LayoutConfigKeyEnum from 'Enum/layoutConfigKeyEnum'
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/joabson_arley/",
      },
    ],
    logoUrl: null,
    footers: []
  }),
  created () {
    let filter = {
      params: {
        key: LayoutConfigKeyEnum.LOGO
      }
    }
    this.GET_LOGO_BG_MENU(filter).then(
      function (res) {
        let data = res.data
        this.logoUrl = data.value
      }.bind(this)
    )
    this.GET_FOOTERS_CONFIG().then(
      function (res) {
        let data = res.data
        this.footers = []
        for (let i = 0, size = data.length; i < size; i++) {
          let obj = {
            title: data[i].title,
            content: data[i].content
          }
          this.footers.push(obj)
        }
      }.bind(this)
    )
  },
  methods: {
    /**
     * Go tern and conditions
     */
    onGoTernAndConditions: function (name) {
      this.$router.push({
        name: name
      })
    },
    ...mapActions(['GET_LOGO_BG_MENU', 'GET_FOOTERS_CONFIG'])
  },
};
</script>
<style scoped>
.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
}
.hr-sect:before,
.hr-sect:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}
</style>