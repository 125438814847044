<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      light
    >
      <div class="mx-auto py-4">
        <!-- <v-list>
          <v-list-item> -->
        <img class="mx-auto" :src="logoUrl" alt="Logo" width="60%"/>
        <img class="mx-auto" :src="logoPartnerUrl" alt="Logo" width="60%"/>
            <!-- <v-list-item-content>
              <v-list-item-title class="title">Product Portal</v-list-item-title>
              <v-list-item-subtitle>WEB</v-list-item-subtitle>
            </v-list-item-content> -->
          <!-- </v-list-item>
        </v-list> -->
      </div>
      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="onGoToPage(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-badge
              v-if="link == '#cart'"
              :content="products"
              :value="products"
              color="green"
              overlap
            >
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-badge>
            <v-icon v-else>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              $t(text)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      light
      :class="{ expand: flat }"
    >
      <div :style="`width: ${$vuetify.breakpoint.xs ? '60%' : '200px;'}`">
        <img :src="logoUrl" width="100%"/>
      </div>
      <span class="mx-4" style="width: 2px; height: 35px; background-color: rgb(204, 204, 204);"></span>
      <div class="ml-4" :style="`width: ${$vuetify.breakpoint.xs ? '60%' : '200px;'}`">
        <img :src="logoPartnerUrl" width="100%"/>
      </div>
      <v-spacer />
      <div v-if="isXs" class="ml-12">
        <v-badge
          :content="products"
          :value="products"
          color="green"
          overlap
        >
          <v-icon @click="onGoToPage('ProductCart')">
            mdi-cart-outline
          </v-icon>
        </v-badge>
      </div>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="isXs"
      />
      <div v-else style="width: 100%; display: flex; justify-content: space-between;">
        <div class="mx-auto">
          <v-btn text @click="goTo('Home')">
            <span class="mr-2 font-weight-bold" :style="`color: ${$route.name == 'Home' ? '#01BAEF;' : '#FE6D6D'}`">{{ $t('menu.home') }}</span>
          </v-btn>
          <v-btn text @click="goTo('Product')">
            <span class="mr-2 font-weight-bold" :style="`color: ${$route.name == 'Product' ? '#01BAEF;' : '#FE6D6D'}`">{{ $t('menu.product') }}</span>
          </v-btn>
          <v-btn text @click="goTo('Contact')">
            <span class="mr-2 font-weight-bold" :style="`color: ${$route.name == 'Contact' ? '#01BAEF;' : '#FE6D6D'}`">{{ $t('menu.contact') }}</span>
          </v-btn>
        </div>
        <div style="align-self: center;">
          <span class="px-2 font-medium cursor-pointer" @click="onChangeLocale">{{ $i18n.locale === "en" ? 'VI' : 'EN' }}</span>
          <span class="px-2 font-medium">{{ $t('menu.support') }}</span>
          <v-badge
            class="px-2"
            :content="products"
            :value="products"
            color="green"
            overlap
          >
            <v-icon @click="onGoCart">
              mdi-cart-outline
            </v-icon>
          </v-badge>
          <v-menu
            class="px-2"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="350"
            open-on-hover
            bottom
            right
            transition="slide-y-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
              v-on="on" 
              v-bind="attrs" class="px-2">mdi-account-circle</v-icon>
            </template>

            <v-card>
              <v-list>
                <v-list-item class="text-left">
                  <v-list-item-avatar>
                    <!-- <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                    > -->
                    <avatar :username="getCurrentLoginName()" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.account') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getCurrentLoginName() }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item @click="onGoToPage('MyOrder')">
                  <v-list-item-action>
                    <v-icon>mdi-cart-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="text-left">{{ $t('navigation.myOrder') }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="onGoToPage('Profile')">
                  <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="text-left">{{ $t('navigation.myAccount') }}</v-list-item-title>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="error"
                  @click="onLogOut"
                >
                  {{ $t('common.logout') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import functionUtils from "Utils/functionUtils";
import LayoutConfigKeyEnum from 'Enum/layoutConfigKeyEnum'
import StoreChannelType from 'Enum/storeChannelType'
import Avatar from 'vue-avatar'
import {
  isCookieEnabled,
  getCookie
} from 'tiny-cookie'
export default {
  components: {
    Avatar
  },
  data: () => ({
    products: 0,
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "menu.home", "Home"],
      // ["mdi-information-outline", "menu.about", "#features"],
      // ["mdi-newspaper", "menu.news", "#distribution"],
      ["mdi-package-variant", "menu.product", "Product"],
      ["mdi-phone-outline", "menu.contact", "Contact"],
      ["mdi-face-agent", "menu.support", "#support"],
      // ["mdi-magnify", "menu.search", "#search"],
      ["mdi-cart-outline", "menu.cart", "ProductCart"],
      ["mdi-account-circle", "menu.profile", "Profile"]
    ],
    logoUrl: null,
    logoPartnerUrl: null,
    color: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  props: {
    flat: Boolean,
  },
  created () {
    // Temporary: force display Vietamese if not EN
    let locale = this.$i18n.locale === "en" ? "en" : "vi";
    this.CHANGE_LOCALE(locale);
    this.getLogoConfig()
    this.getBgColorConfig()
    this.getLogoPartnerConfig()
    let cartList = sessionStorage.getItem('carts')
    cartList = JSON.parse(cartList)
    this.products = this.updateNumOfItemInCart(cartList)
    this.getVoucherOrderCart()
  },
  methods: {
    getCurrentLoginName: function () {
      return this.CURRENT_ACCOUNT_DATA ? functionUtils.concatFullname(this.CURRENT_ACCOUNT_DATA.last_name, this.CURRENT_ACCOUNT_DATA.first_name) : ''
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled() ? getCookie('token') : null
      this.LOGOUT(token).then(
        function () {
          this.$toast.open({
            message: this.$t('common.success'),
            type: "success",
            ...this.$toastConfig,
          });
          this.$router.push({
            name: 'ConfirmActiveKeyView'
          })
        }.bind(this)
      )
    },
    /**
     * Change locale
     */
    onChangeLocale(){
      let locale = this.$i18n.locale === 'en' ? 'vi' : 'en'
      this.CHANGE_LOCALE(locale);
    },
    onGoToPage: function (name) {
      this.$router.push({
        name: name
      })
    },
    getBgColorConfig: function () {
      let filter = {
        params: {
          key: LayoutConfigKeyEnum.BACKGROUND_MENU
        }
      }
      this.GET_LOGO_BG_MENU(filter).then(
        function (res) {
          let data = res.data
          this.color = data.value
        }.bind(this)
      )
    },
    /**
     * Get logo config
     */
    getLogoConfig: function () {
      let filter = {
        params: {
          key: LayoutConfigKeyEnum.LOGO
        }
      }
      this.GET_LOGO_BG_MENU(filter).then(
        function (res) {
          let data = res.data
          this.logoUrl = data.value
        }.bind(this)
      )
    },
    /**
     * Get logo config
     */
    getLogoPartnerConfig: function () {
      let filter = {
        params: {
          key: LayoutConfigKeyEnum.LOGO_PARTNER
        }
      }
      this.GET_LOGO_BG_MENU(filter).then(
        function (res) {
          let data = res.data
          this.logoPartnerUrl = data.value
        }.bind(this)
      )
    },
    /**
     * Go cart products
     */
    onGoCart: function () {
      this.$router.push({
        name: 'ProductCart'
      })
    },
    onResize() {
      this.isXs = window.innerWidth < 1000;
    },
    /**
     * Go to
     */
    goTo: function (name) {
      this.$router.push({
        name: name
      })
    },
    updateNumOfItemInCart: function (cartList) {
      let quantity = 0
      if (cartList && cartList.length > 0) {
        for (let i = 0, size = cartList.length; i < size; i++) {
          let productQuantity = cartList[i].number_of_uses_voucher_valid !== 0 ? cartList[i].quantity : 0
          quantity += productQuantity
        }
      }
      return quantity
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    ...mapActions(['GET_LOGO_BG_MENU', 'CHANGE_LOCALE', 'LOGOUT', 'GET_VOUCHER_ORDER_CART_LIST'])
  },
  computed: {
    ...mapGetters(['GET_VOUCHER_ORDER_CART_LIST_DATA', 'CURRENT_ACCOUNT_DATA'])
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.products = this.updateNumOfItemInCart(data)
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
