<template>
  <v-app>
    <v-main>
      <div v-show="!$vuetify.breakpoint.xs || !isHiddenHeader" :style="`width: 100%; height: ${$vuetify.breakpoint.xs ? '130px' : '66px'}; z-index: 2; margin-top: ${$vuetify.breakpoint.xs ? '36px' : '24px'}; position: fixed;`">
        <v-row class="d-flex">
          <v-col
            cols="12"
            md="6"
            :class="[$vuetify.breakpoint.xs ? 'pa-4' : '']"
            style="background-color: #FE6D6D;"
          >
            <div class="mt-3 mx-auto text-center">
              <span style="color: #fff;">{{ $t('common.welcomeToConasi') }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            style="background-color: #FE6D6D;"
          >
            <div class="mx-auto" :style="`width: ${$vuetify.breakpoint.xs ? '90%;' : '50%'}`">
              <v-text-field
                v-model="search"
                @keypress.enter="onGoProductList"
                :label="$t('menu.search')"
                :placeholder="$t('product.enterInfo') + '...'"
                hide-details="auto"
                append-icon="mdi-magnify"
                solo
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </div>
        <navigation v-show="!$vuetify.breakpoint.xs || !isHiddenHeader" :flat="flat" />
        <!-- <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`"> -->
          <core-view :style="`margin-top: ${$vuetify.breakpoint.xs ? '100px' : '20px'};`"/>
        <!-- </v-container> -->
        <v-scale-transition>
          <v-btn
            fab
            v-show="fab"
            v-scroll="onScroll"
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </v-scale-transition>
        <div v-if="bannerLeft && !$vuetify.breakpoint.xs" style="position: absolute; left: 0; top: 30%; z-index: 1;">
          <img :src="bannerLeft" />
        </div>
        <div v-if="bannerRight && !$vuetify.breakpoint.xs" style="position: absolute; right: 0; top: 30%; z-index: 1;">
          <img :src="bannerRight" />
        </div>
    </v-main>
    <foote />
  </v-app>
</template>
<script>
import navigation from "Components/Navigation";
import foote from "Components/Footer";
// import home from "Views/Home/Home"
import coreView from "Components/CoreView"
import { mapActions } from 'vuex';
import { isCookieEnabled, getCookie } from "tiny-cookie";
import BannerTypeEnum from "Enum/bannerTypeEnum";
import PositionBannerTypeEnum from 'Enum/positionBannerTypeEnum'
export default {
  components: {
    navigation,
    foote,
    coreView
    // home
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    search: null,
    isHiddenHeader: false,
    currentOffset: 0,
    bannerLeft: null,
    bannerRight: null
  }),
  created () {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
    let filter = {
      params: {
        type: BannerTypeEnum.VERTICAL,
      },
    };
    this.GET_BANNER_OF_ENTITY_BY_TYPE(filter).then(
      function(res) {
        let data = res.data
        for (let i = 0, size = data.length; i < size; i++) {
          if (data[i].position === PositionBannerTypeEnum.LEFT) {
            this.bannerLeft = data[i].image_url
          } else if (data[i].position === PositionBannerTypeEnum.RIGHT) {
            this.bannerRight = data[i].image_url
          }
        }
      }.bind(this)
    );
  },
  watch: {
    $route (){
      this.$vuetify.goTo(0);
    },
    fab(value) {
      if (value) {
        this.color = "primary";
        this.flat = true;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    }
  },
  mounted () {
    const userId = isCookieEnabled() ? getCookie("userId") : null;
    this.GET_CURRENT_ACCOUNT({ id: userId })
      .then(
        function(res) {
          let data = res.data
          sessionStorage.setItem('is_changed_password_social', data.is_changed_password_social)
        }.bind(this)
      )
  },
  methods: {
    /**
     * Go product list
     */
    onGoProductList: function () {
      this.$router.push({
        name: 'Product',
        query: {
          search: this.search
        }
      })
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      if (window.pageYOffset >= 300) {
        this.isHiddenHeader = true
      } else {
        this.isHiddenHeader = false
      }
      this.currentOffset = window.pageYOffset
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    ...mapActions(['GET_CURRENT_ACCOUNT', 'GET_BANNER_OF_ENTITY_BY_TYPE'])
  }
}
</script>
<style lang="scss">
</style>
